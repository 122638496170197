<template lang="">
  <div>
    <h4 class="mt-4">Ödemeler</h4>
    <div
      class="d-flex align-items-center justify-content-between search-list-area mt-4"
    >
      <div class="d-flex align-items-center search-input">
        <select v-model="list_length" class="form-control ml-2 w-100">
          <option value="5" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-primary mr-4" @click="addPayment = true">
          Ödeme Ekle
        </button>
      </div>
    </div>
    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      moduleName="Ödemeler Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.OdemeTuru="{ item }">
        <b-badge variant="primary">{{ item.OdemeTuru }}</b-badge>
      </template>
      <template v-slot:item.Fiyat="{ item }">
        <p class="text-dark">{{ item.Fiyat }} {{ item.ParaBirimi }}</p>
      </template>
      <template v-slot:item.Aciklama="{ item }">
        <Moretext :value="item" :field="'Aciklama'" />
      </template>
      <template v-slot:item.OdemeDurumu="{ item }">
        <b-badge variant="success" v-if="item.OdemeDurumu === 'Onaylandı'"
          >Onaylandı</b-badge
        >
        <b-badge variant="danger" v-else-if="item.OdemeDurumu === 'Reddedildi'"
          >Reddedildi</b-badge
        >
        <b-badge variant="warning" v-else-if="item.OdemeDurumu === 'Bekliyor'"
          >Bekliyor</b-badge
        >
      </template>

      <template v-slot:item.action="{ item }" class="p-0">
        <b-dropdown
          size="sm"
          no-caret
          dropleft
          variant="muted"
          style="z-index: 999"
        >
          <template v-slot:button-content>
            <i class="ki ki-bold-more-ver text-dark"></i>
          </template>
          <b-dropdown-item @click="openDetail(item)">Detay</b-dropdown-item>
          <b-dropdown-item @click="openInsertUpdate(item)"
            >Düzenle</b-dropdown-item
          >
          <b-dropdown-item
            v-if="item.IsDeleted === false"
            @click="openDeleteHandler(item)"
            >Sil</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </List>

    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteCustomer"
    />
    <b-modal v-model="addPayment" title="Ödeme Ekle" size="lg">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="turu">Ödeme Türü</label>
            <select name="" id="turu" class="form-control" v-model="OdemeTuru">
              <option value="Kira">Kira</option>
              <option value="Dask">Dask</option>
              <option value="Aidat">Aidat</option>
              <option value="Özel Ödemeler">Özel Ödemeler</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="sekil">Ödeme Şekli</label>
            <select
              name=""
              id="sekil"
              class="form-control"
              v-model="OdemeSekli"
            >
              <option value="Havale">Havale</option>
              <option value="EFT">EFT</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="fiyat">Fiyat</label>
            <input
              type="text"
              id="fiyat"
              class="form-control"
              v-model="Fiyat"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="pbirim">Param Birimi</label>
            <select
              name=""
              id="pbirim"
              class="form-control"
              v-model="ParaBirimi"
            >
              <option value="Euro">Euro</option>
              <option value="Dolar">Dolar</option>
              <option value="TL">TL</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="odurum">Ödeme Durumu</label>
            <select
              name=""
              id="odurum"
              class="form-control"
              v-model="OdemeDurumu"
            >
              <option value="Bekleniyor">Bekleniyor</option>
              <option value="Onaylandı">Onaylandı</option>
              <option value="Reddedildi">Reddedildi</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="bildirim">Bildirim Türü</label>
            <select
              name=""
              id="bildirim"
              class="form-control"
              v-model="BildirimTuru"
            >
              <option value="Sistem Bildirimi">Sistem Bildirimi</option>
              <option value="Sms">Sms</option>
              <option value="Mail">Mail</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="tarih">Ödeme Tarihi</label>
            <input type="date" class="form-control" v-model="OdemeTarihi" />
          </div>
        </div>
        <div class="col-12">
          <label for="aciklama">Açıklama</label>
          <textarea
            name=""
            id="aciklama"
            rows="3"
            class="form-control"
            v-model="Aciklama"
          ></textarea>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex align-items-center justify-content-between w-100">
          <b-button
            type="reset"
            variant="link"
            @click="addPayment = false"
            class="text-dark text-hover-dark-50 text-decoration-none"
            >İptal</b-button
          >

          <b-button
            type="submit"
            variant="primary"
            class="ml-auto"
            ref="submitButton"
            @click="sendForm"
            >Ekle</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "mulk-odemeler",
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames: ["Hazırlık", "Satış", "Devir"],
      header: [
        { text: "", value: "_id", checkboxField: true, size: "50px" },
        { text: "Ödeme Turu", value: "OdemeTuru" },
        { text: "Ödeme Şekli", value: "OdemeSekli" },
        { text: "Fiyat", sortable: true, value: "Fiyat" },
        { text: "Açıklama", moreText: true, value: "Aciklama" },
        { text: "Ödeme Tarihi", value: "OdemeTarihi" },
        { text: "Ödeme Durumu", value: "OdemeDurumu" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList: [],
      list_length: 10,
      openFullTextModal: false,
      addPayment: false,
      OdemeTuru: "",
      OdemeSekli: "",
      ParaBirimi: "",
      Fiyat: "",
      OdemeDurumu: "",
      BildirimTuru: "",
      Aciklama: "",
      OdemeTarihi: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [];
      this.totalItems = 0;
      this.loading = true;
      let id = this.$route.params.id;
      let queryLimit = this.list_length ? this.list_length : 10;
      let query = `mulkodemeleri_list?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&token=${
        this.myUser
      }&column=MulkId&searchValue=${id}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$ApiService
        .get(query)
        .then((res) => {
          if (res.status == 200) {
            this.items = res?.data ? res.data : [];
            this.totalItems = res?.data?.length;
            this.pagination = {
              current_page: this.page,
              total_pages: 3,
              total_items: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendForm() {
      const data = {
        token: this.myUser,
        MulkId: this.getProperty._id.$oid,
        KullanicilarId: this.getProperty.KiraciId,
        MusteriId: this.getProperty.MulkSahibiId,
        OdemeDurumu: this.OdemeDurumu,
        OdemeSekli: this.OdemeSekli,
        OdemeTarihi: this.OdemeTarihi,
        ParaBirimi: this.ParaBirimi,
        OdemeTuru:this.OdemeTuru,
        Aciklama: this.Aciklama,
        Fiyat: this.Fiyat,
        OlusturmaZamani: new Date(),
        OlusturmaId: 1,
        OlusturmaIp: "1.1.1.1",
        IsDeleted: Boolean(false),
      };
      this.$ApiService
        .post("mulkodemeleri_create", data)
        .then((Response) => {
          if (Response.data.status === 200) {
            this.$generateNotification(this, "success", Response.data.result);
            this.addPayment = false
            this.getList()
          } else {
            this.$generateNotification(this, "error", Response.data.result);
          }
        })
        .catch((err) => {
          this.$generateNotification(this, "error", err);
        });
    },
  },
  computed: {
    ...mapGetters(["myUser", "getProperty"]),
  },
  components: {
    Moretext: () => import("@/components/global/Moretext"),
  },
};
</script>
